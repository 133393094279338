import { useEffect, useState } from "react";

import { Notification } from "../../../../@types/Notification";
import { BillingPatientListConfig } from "../../../../@types/Patient";
import { Button } from "../../../../components/Button";
import { Checkbox } from "../../../../components/Checkbox";
import { userService } from "../../../../services/userService";
import { mixpanelActions } from "../../../../utils/mixpanel";
import { SubmitContainer } from "../../../PatientList/components/AddPatientModal/AddPatientModal.styles";
import {
  Subtitle,
  Title,
} from "../../../PatientPage/components/PatientLateralMenu/components/ConfirmChangesModal.styles";
import { CancelButton } from "../../../Settings/components/SecurityModal/SecurityModal.styles";
import {
  ColumnTitle,
  Row,
  Container,
} from "./BillingPatientListConfigModal.styles";

type PatientListConfigProps = {
  columnsConfig: BillingPatientListConfig;
  onClose(
    listColumnConfig?: BillingPatientListConfig,
    notification?: Notification,
    refreshTable?: boolean
  ): void;
};

export function BillingPatientListConfigModal({
  columnsConfig,
  onClose,
}: PatientListConfigProps) {
  const [confirmChanges, setConfirmChanges] = useState(false);
  const [listColumnConfig, setListColumnConfig] =
    useState<BillingPatientListConfig>(columnsConfig);

  useEffect(() => {
    mixpanelActions.track("Visited Screen: BillingPatientListConfiguration");
  }, []);

  const handleValueChange = (value: boolean, propertyName: string) => {
    mixpanelActions.track(`User Action: Checkbox ${propertyName}`);

    const temporaryList = { ...listColumnConfig } as any;
    temporaryList[propertyName] = value;

    setListColumnConfig(temporaryList);
  };

  const handleOnConfirm = async () => {
    mixpanelActions.track("User Action: ConfigureBillingTableConfirm");
    if (confirmChanges) {
      const tableConfigJson = JSON.stringify(listColumnConfig);

      const result = await userService.updateBillingTableColumnPreferences(
        tableConfigJson
      );

      if (result.status >= 200 && result.status < 300) {
        onClose(
          listColumnConfig,
          {
            show: true,
            message: "Configuration successfully saved",
            type: "success",
            width: "max-content",
          },
          true
        );
        return;
      }

      onClose(
        undefined,
        {
          show: true,
          message: "Sorry, something went wrong. Please try again",
          type: "error",
          width: "max-content",
        },
        true
      );
    }

    setConfirmChanges(true);
  };

  return (
    <Container>
      {!confirmChanges ? (
        <>
          <Row style={{ marginBottom: "26px" }}>
            <ColumnTitle>Column</ColumnTitle>
            <ColumnTitle>Show</ColumnTitle>
          </Row>

          <Row disabled>
            <p>Name</p>
            <Checkbox
              checked={listColumnConfig.name}
              onChange={() => {}}
              label=""
              labelPosition="before"
              disabled
            />
          </Row>

          <Row disabled>
            <p>No. of days recording data </p>
            <Checkbox
              checked={listColumnConfig.numberOfBillableDays}
              onChange={(isChecked) =>
                handleValueChange(isChecked, "numberOfBillableDays")
              }
              label=""
              labelPosition="before"
              disabled
            />
          </Row>

          <Row>
            <p>Hospital ID</p>
            <Checkbox
              checked={listColumnConfig.hospitalPatientId}
              onChange={(isChecked) =>
                handleValueChange(isChecked, "hospitalPatientId")
              }
              label=""
              labelPosition="before"
            />
          </Row>

          <Row>
            <p>pMp ID</p>
            <Checkbox
              checked={listColumnConfig.pmpId}
              onChange={(isChecked) => handleValueChange(isChecked, "pmpId")}
              label=""
              labelPosition="before"
            />
          </Row>

          <Row>
            <p>DOB</p>
            <Checkbox
              checked={listColumnConfig.dob}
              onChange={(isChecked) => handleValueChange(isChecked, "dob")}
              label=""
              labelPosition="before"
            />
          </Row>

          <Row>
            <p>Sign up date</p>
            <Checkbox
              checked={listColumnConfig.signUpDate}
              onChange={(isChecked) =>
                handleValueChange(isChecked, "signUpDate")
              }
              label=""
              labelPosition="before"
            />
          </Row>

          <Row>
            <p>Last used</p>
            <Checkbox
              checked={listColumnConfig.lastUsed}
              onChange={(isChecked) => handleValueChange(isChecked, "lastUsed")}
              label=""
              labelPosition="before"
            />
          </Row>
        </>
      ) : (
        <>
          <Title>Confirm changes</Title>
          <Subtitle larger style={{ marginBottom: "-30px", fontWeight: "400" }}>
            These configuration changes are saved to only your profile. To
            change for all staff members, please contact your admin.
          </Subtitle>
        </>
      )}

      <SubmitContainer style={{ margin: "49px 0 8px 3px" }}>
        <Button
          label="Confirm"
          type="button"
          onClick={handleOnConfirm}
          isLoading={false}
        />

        <CancelButton
          onClick={() => {
            mixpanelActions.track("User Action: ConfigurePatientListCancel");
            onClose();
          }}
          style={{ marginLeft: "-34px" }}
        >
          Cancel
        </CancelButton>
      </SubmitContainer>
    </Container>
  );
}
