import React, { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";

import {
  DatePickerCalendar,
  DatePickerContainer,
  DatePickerTextField,
  TextFieldsGroup,
} from "./DatePickerInput.styles";
import { DatePickerInputProps, DateRangeArray } from "./DatePickerInput.types";
import "react-datepicker/dist/react-datepicker.css";
import { maskDDMMYYYY } from "./DatePickerInputHelper";

export function DatePickerInput({
  initialDateRange,
  hideTextFields,
  initialDate,
  onDateChange,
}: DatePickerInputProps) {
  const dateFormats: any = {
    "da-DK": "dd-MM-yyyy",
    "de-DE": "dd.MM.yyyy",
    "el-GR": "d/M/yyyy",
    "en-GB": "dd/MM/yyyy",
    "en-IE": "dd/MM/yyyy",
    "en-US": "M/d/yyyy",
    "es-ES": "dd/MM/yyyy",
    "fr-FR": "dd/MM/yyyy",
    "it-IT": "dd/MM/yyyy",
    "nl-NL": "d-M-yyyy",
  };

  const decimalBase = 10;
  const dateFormat = dateFormats[navigator.language] || "dd/MM/yyyy";
  const maxDatePickerFieldLenth = dateFormat.length;

  const [startDateField, setStartDateField] = useState("");
  const [endDateField, setEndDateField] = useState("");
  const [selectedDateField, setSelectedDateField] = useState(
    initialDate?.toLocaleDateString(navigator.language)
  );

  const [dateRange, setDateRange] = useState<DateRangeArray>([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedDate, setSelectedDate] = useState(initialDate);

  function updateDateRange(
    startDate: Date | null,
    endDate: Date | null,
    fireOnDateChangeEvent = true
  ) {
    setDateRange([startDate, endDate]);

    if (startDate) {
      setStartDateField(startDate.toLocaleDateString(navigator.language));
    }

    if (endDate) {
      setEndDateField(endDate.toLocaleDateString(navigator.language));
    }

    if (fireOnDateChangeEvent && onDateChange)
      onDateChange({ startDate, endDate });
  }

  useEffect(() => {
    if (initialDateRange) {
      const { startDate, endDate } = initialDateRange;
      updateDateRange(startDate, endDate, false);
      if (startDate) {
        setSelectedDateField(startDate.toLocaleDateString(navigator.language));
      }
    }
  }, []);

  function toNullableDate(textDateDDMMYYYY: string) {
    const [date, month, year] = textDateDDMMYYYY.split("/");

    const nullableDate: Date | null = new Date(
      parseInt(year, decimalBase),
      parseInt(month, decimalBase) - 1,
      parseInt(date, decimalBase)
    );

    return nullableDate;
  }

  function clearDatePickerTextFields() {
    setStartDateField("");
    setEndDateField("");
  }

  function resetDatePicker() {
    clearDatePickerTextFields();
    updateDateRange(null, null);
  }

  function getDatePickerValueStatus(value: string) {
    const isIncompleteDate = value.length <= maxDatePickerFieldLenth;
    const isCompleteDate = value.length === maxDatePickerFieldLenth;
    const isBlank = value.length === 0;

    return [isIncompleteDate, isCompleteDate, isBlank];
  }

  const handleSelectedDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;

    const [isIncompleteDate, isCompleteDate, isBlank] =
      getDatePickerValueStatus(value);

    if (isBlank) resetDatePicker();

    if (isIncompleteDate) {
      const maskedDateValue = maskDDMMYYYY(value);
      setSelectedDateField(maskedDateValue);

      if (isCompleteDate) {
        const newSelectedDate = toNullableDate(maskedDateValue);
        setSelectedDate(newSelectedDate);
        if (onDateChange) {
          onDateChange({ startDate: newSelectedDate, endDate: null });
        }
      }
    }
  };

  const handleOnStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const [isIncompleteDate, isCompleteDate, isBlank] =
      getDatePickerValueStatus(value);

    if (isBlank) resetDatePicker();

    if (isIncompleteDate) {
      const maskedDateValue = maskDDMMYYYY(value);
      setStartDateField(maskedDateValue);

      if (isCompleteDate) {
        const newStartDate = toNullableDate(maskedDateValue);
        updateDateRange(newStartDate, endDate);
      }
    }
  };

  const handleOnEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const [isIncompleteDate, isCompleteDate, isBlank] =
      getDatePickerValueStatus(value);

    if (isBlank) resetDatePicker();

    if (isIncompleteDate) {
      const maskedDateValue = maskDDMMYYYY(value);
      setEndDateField(maskedDateValue);

      if (isCompleteDate) {
        const newEndDate = toNullableDate(maskedDateValue);
        updateDateRange(startDate, newEndDate);
      }
    }
  };

  const handleOnDatePickerChange = (update: any) => {
    clearDatePickerTextFields();

    const [startDate, endDate] = update;
    updateDateRange(startDate, endDate);
  };

  const handleSingleDateChange = (date: Date | null, event: any) => {
    clearDatePickerTextFields();

    if (date) {
      setSelectedDateField(date.toLocaleDateString(navigator.language));
      setSelectedDate(date);

      if (onDateChange && event !== undefined) {
        onDateChange({ startDate: date, endDate: null });
      }
    }
  };

  return (
    <DatePickerContainer>
      {!hideTextFields && (
        <TextFieldsGroup singeDate={initialDate !== undefined}>
          {initialDate === undefined ? (
            <>
              <DatePickerTextField
                disabled
                label="Start date"
                placeholder={dateFormat.toUpperCase()}
                value={startDateField}
                onChange={handleOnStartChange}
              />
              <DatePickerTextField
                disabled
                label="End date"
                placeholder={dateFormat.toUpperCase()}
                value={endDateField}
                onChange={handleOnEndChange}
              />
            </>
          ) : (
            <DatePickerTextField
              label="Selected date"
              placeholder={dateFormat.toUpperCase()}
              value={selectedDateField ?? ""}
              onChange={handleSelectedDateChange}
            />
          )}
        </TextFieldsGroup>
      )}

      <DatePickerCalendar>
        {initialDate === undefined ? (
          <ReactDatePicker
            selectsRange
            dateFormat={dateFormat}
            startDate={startDate}
            endDate={endDate}
            onChange={handleOnDatePickerChange}
            isClearable
            inline
            formatWeekDay={(weekDay) => weekDay.toString().slice(0, 3)}
          />
        ) : (
          <ReactDatePicker
            adjustDateOnChange
            dateFormat={dateFormat}
            selected={selectedDate}
            onChange={handleSingleDateChange}
            isClearable
            inline
            formatWeekDay={(weekDay) => weekDay.toString().slice(0, 3)}
          />
        )}
      </DatePickerCalendar>
    </DatePickerContainer>
  );
}
