import { styled, css } from "../../../../styles";

// Components

export const WorkflowsBuilderContainer = styled("div", {
  width: "100vw",
  height: "100vh",
  position: "relative",
  marginTop: "-62px",
  zIndex: 2001,
  backgroundColor: "$gray-20",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const Header = styled("div", {
  width: "100%",
  height: "62px",

  position: "absolute",

  backgroundColor: "$primary-80",
  padding: "0 40px 0 68px",
  boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.16)",

  display: "flex",
  alignItems: "center",

  p: {
    color: "$white",
    fontSize: "16px",
    fontWeight: "00",
    lineHeight: "22px",
  },
});

export const ButtonsContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "12px",

  position: "absolute",
  right: "40px",
});

export const CancelButton = styled("div", {
  width: "110px !important",
  height: "38px",

  padding: "0px 40px",
  borderRadius: "20px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "22px",
  color: "$white",

  cursor: "pointer",

  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.15)",
  },

  "&:focus-visible": {
    outline: "2px solid white !important",
  },
});

export const SectionsHeader = styled("div", {
  width: "100%",
  height: "62px",
  marginTop: "62px",
  backgroundColor: "$gray-20",
  padding: "0 40px 0 68px",
  boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.16)",
  zIndex: 100,
});

export const SectionsContainer = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "42px",
});

export const SectionButton = styled("div", {
  color: "$gray-90",
  fontFamily: "$openSans",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "22px",
  marginTop: "20px",

  cursor: "pointer",

  "&:hover": {
    div: {
      backgroundColor: "$primary-20",
    },
  },

  "&:focus": {
    outline: "none",

    div: {
      backgroundColor: "$primary-20",
    },
  },
});

export const Underline = styled("div", {
  width: "100%",
  height: "5px",

  borderRadius: "2px",
  marginTop: "15px",

  variants: {
    isActive: {
      true: {
        backgroundColor: "$primary-30 !important",
      },
    },
  },
});

export const ReactFlowContainer = styled("div", {
  width: "calc(100vw - 460px)",
  height: "calc(100vh - 124px)",

  display: "flex",

  backgroundColor: "$white",
  borderRight: "1px solid #CFCFCF",
  borderLeft: "1px solid #CFCFCF",

  position: "relative !important",

  ".node-wrapper": {
    pointerEvents: "all !important",
  },

  ".node-wrapper .interactive-element": {
    pointerEvents: "all !important",
  },
});

export const SettingsContainer = styled("div", {
  width: "60%",
  height: "100%",

  backgroundColor: "$white",
  borderRight: "2px solid $gray-40",
  borderLeft: "2px solid $gray-40",

  padding: 48,
});

export const SettingsChildContainer = styled("div", {
  width: "100%",
  height: 346,
  padding: "46px 68px",

  borderRadius: 8,
  backgroundColor: "rgba(248, 248, 248, 0.8)",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",

  gap: 26,
});

export const SettingsLabel = styled("p", {
  width: 140,
  fontFamily: "$openSans",
  fontWeight: 600,
  fontSize: 14,
  lineHeight: 14,

  color: "$charcoal",
});

export const Row = styled("div", {
  height: 42,
  width: "100%",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

  position: "relative",

  variants: {
    isError: {
      true: {
        ".ant-select-selector": {
          borderColor: "$error-lighter !important",

          "&:hover": {
            borderColor: "$error-lighter !important",
          },
        },

        input: {
          borderColor: "$error-lighter!important",
        },
      },
    },

    isSaveSettings: {
      true: {
        marginTop: 24,
      },
    },
  },
});

export const ErrorMessage = styled("p", {
  zIndex: 3002,

  margin: "-6px 0 0 6px",

  fontWeight: "600 !important",
  lineHeight: "16px !important",
  fontSize: "$text-md !important",

  color: "$error-light !important",

  variants: {
    isSettingsPageError: {
      true: {
        position: "absolute",
        left: 140,
        bottom: -20,
      },
    },
  },
});

// CSS

export const spinCss = css({
  backgroundColor: "white !important",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  ".ant-spin-dot": {
    ".ant-spin-dot-item": {
      backgroundColor: "#7562A8",
    },
  },
});

export const whiteButton = css({
  button: {
    backgroundColor: "$white !important",
    borderColor: "$white !important",

    p: {
      color: "$primary-70 !important",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "22px",
    },

    "&:hover": {
      backgroundColor: "$gray-40 !important",
    },

    "&:focus-visible": {
      outline: "2px solid $primary-30 !important",
    },
  },
});

export const purpleButton = css({
  button: {
    backgroundColor: "$primary-40 !important",
    borderColor: "$primary-40 !important",

    p: {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "22px",
    },

    "&:hover": {
      backgroundColor: "$primary-30 !important",
    },

    "&:focus-visible": {
      outline: "2px solid white !important",
    },
  },
});

export const selectCss = css({
  height: "42px !important",
  wordWrap: "break-word",
  inlineSize: 250,

  ".ant-select-selector": {
    height: "42px !important",
    alignItems: "center",
    border: "solid 1px $gray-50 !important",
    boxShadow: "0 0 0 2px $gray-50 !important",
    fontFamily: "$openSans !important",

    "&:focus-within": {
      borderColor: "$primary-30 !important",
    },

    "&:hover": {
      borderColor: "$primary-30 !important",
    },

    ".ant-select-selection-overflow-item": {
      span: {
        "&.ant-select-selection-item": {
          borderRadius: "14px !important",
        },
      },
    },
  },
});

export const textAreaCss = css({
  height: "max-content !important",
});

export const wardsSelectCss = css({
  height: "42px !important",
  width: "50% !important",
  maxWidth: "300px !important",

  ".ant-select-selector": {
    height: "42px !important",
    alignItems: "center",
    border: "solid 1px $gray-50 !important",
    boxShadow: "0 0 0 2px $gray-50 !important",
    fontFamily: "$openSans !important",

    "&:focus-within": {
      borderColor: "$primary-30 !important",
    },

    "&:hover": {
      borderColor: "$primary-30 !important",
    },

    ".ant-select-selection-overflow-item": {
      span: {
        "&.ant-select-selection-item": {
          borderRadius: "14px !important",
        },
      },
    },
  },
});

export const TextFieldCss = css({
  width: "75% !important",
});
