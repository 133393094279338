import { Counter } from "./AlertsCounter.styles";

type AlertsCounterProps = React.ComponentProps<typeof Counter> & {
  children: React.ReactNode;
};

export function AlertsCounter({ children, ...rest }: AlertsCounterProps) {
  interface IStringByString {
    [key: string]: string;
  }

  const abbreviations: IStringByString = {
    dry_weight: "Dry weight",
    weight: "Weight",
    height: "Height",
    bp_systolic: "BP systolic",
    bp_diastolic: "BP diastolic",
    creatinine: "Creatinine",
    hemoglobin: "Hemoglobin",
    cni_trough: "Tacrolimus",
    white_cells: "WBC",
    viral_load: "Viral Load",
    psa: "PSA",
    blood_glucose: "Blood glucose",
    hba1c: "HbA1c",
    heart_rate: "HR",
    pulse_ox: "SpO2",
    temperature: "Temperature",
    dyspnea: "Breathlessness",
    fvc: "FVC",
    pef: "PEF",
    fev1: "FEV1",
    fev1_fvc: "FEV1/FVC",
    fev6: "FEV6",
    fev25_75: "FEF25/75",
    phosphate: "Phosphate",
    esr: "ESR",
    crp: "CRP",
    urine_produced: "Urine produced",
    oxygen_rate: "O2 flow rate",
    fluid_intake: "Fluid intake",
    jp_drain: "Jason-Pratt drain",
    sputum_volume: "Sputum volume",
    respiratory_rate: "Respiratory rate",
    barthel_index: "Modified Barthel Index",
    rockwood_index: "Rockwood Frailty Index",
  };

  let numberOfAlerts = children?.toString();
  let wideAlertSize;

  if (numberOfAlerts) {
    const parsedAlerts = Number(numberOfAlerts);

    if (Number.isNaN(parsedAlerts) && numberOfAlerts.length >= 2) {
      numberOfAlerts = abbreviations[numberOfAlerts] || numberOfAlerts;
      wideAlertSize = {
        width: "max-content",
        padding: "0 12px 0 12px",
      };
    } else if (parsedAlerts > 9) {
      numberOfAlerts = "9+";
    }
  }

  return (
    <Counter style={wideAlertSize} {...rest}>
      {numberOfAlerts}
    </Counter>
  );
}
