import { useMutation, useQueryClient } from "@tanstack/react-query";

import { patientService } from "../../services/patientService";

export function useFlagPatient() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: async ({
      patientId,
      flagged,
    }: {
      patientId: string | number;
      flagged: boolean;
    }) => {
      return patientService.flagPatient({ id: patientId, flagged });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["patient-list-flagged"] });
    },
  });

  return mutateAsync;
}
