import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import ContextCompose from "./components/ContextComponse/ContextCompose";
import { queryClient } from "./config/query";
import { ArrowsListennerHandlerProvider } from "./contexts/ArrowsListennerHandler";
import { AuthProvider } from "./contexts/AuthContext";
import { ChildRoutesProvider } from "./contexts/ChildRoutesContext";
import { DynamicSurveysProvider } from "./contexts/DynamicsSurveys.Context";
import { IntercomCustomProvider } from "./contexts/IntercomContext";
import { LeftPanelProvider } from "./contexts/LeftPanelContext";
import { ModalProvider } from "./contexts/ModalContext";
import { UserPreferencesProvider } from "./contexts/UserPreferencesContext";

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ContextCompose
        components={[
          DynamicSurveysProvider,
          AuthProvider,
          ChildRoutesProvider,
          LeftPanelProvider,
          ModalProvider,
          IntercomCustomProvider,
          UserPreferencesProvider,
          ArrowsListennerHandlerProvider,
        ]}
      >
        <App />
      </ContextCompose>
    </BrowserRouter>
  </QueryClientProvider>
);
