import { styled } from "../../../../styles";

export const Container = styled("div", {
  display: "flex",

  height: "100%",

  "@media only screen and (max-width: 1100px) and (min-width: 900px)": {
    width: "100%",
  },

  "@media only screen and (max-width: 899px)": {
    width: "calc(100vw - 70px)",
    overflowX: "scroll",
  },
});

export const MeasurementContainer = styled("div", {
  height: "100%",
  width: "100%",

  padding: "24px 0px 0 0",

  position: "relative",
});

export const Title = styled("h1", {
  fontWeight: 500,
  fontSize: "$text-xl",
  lineHeight: "22px",

  color: "$black",
});

export const Subtitle = styled("h2", {
  marginTop: "4px",
  marginBottom: "12px",

  color: "$gray-80",

  fontWeight: 400,
  fontSize: "12px",

  lineHeight: "16px",
});

export const HeaderContainer = styled("div", {
  display: "flex",
  flexDirection: "column",

  marginLeft: "40px",
  marginBottom: "28px",

  "&:last-child": {
    marginBottom: "28px",
  },
});

export const SpirometryListTitle = styled("div", {
  lineHeight: "16px",
  textAlign: "end",

  marginRight: "8px",
  marginBottom: "8px",
  marginTop: "60px",

  fontWeight: 600,
  fontSize: "$text-md",

  color: "$gray-90",

  display: "flex",
  justifyContent: "flex-end",

  p: {
    paddingLeft: "11px",
  },

  "@sm": {
    width: "100%",
    justifyContent: "flex-start",
    padding: "0 16px 0 1px",
  },
});

export const OptionsContainer = styled("div", {
  position: "absolute",
  top: 14,
  right: 44,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  cursor: "pointer",
});

export const PinnedToogleContainer = styled("div", {
  top: 20,
  right: -10,
  width: 218,
  height: 48,

  zIndex: 3000,
  borderRadius: "16px",
  position: "absolute",
  background: "white",
  boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.12)",
  border: "1px solid var(--colors-gray-50)",
  padding: "14px 22px",

  color: "$charcoal",
  fontSize: "10px",
  fontWeight: 400,
  fontFamily: "$openSans",
  lineHeight: "16px",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 22,
});

export const DotsContainer = styled("div", {});
