import { Tooltip } from "antd";
import dayjs from "dayjs";
import { useMemo, useState } from "react";

import {
  IAppointment,
  IAppointmentTableData,
} from "../../../../@types/Appointment";
import { Notification } from "../../../../@types/Notification";
import { Delete } from "../../../../assets/icons/Delete";
import { Button } from "../../../../components/Button";
import { Table } from "../../../../components/Table";
import { TableSkeleton } from "../../../../components/TableSkeleton";
import { Tag } from "../../../../components/Tag";
import { appointmentsService } from "../../../../services/appointmentsService";
import { mixpanelActions } from "../../../../utils/mixpanel";
import {
  AppointmentsContainer,
  DeleteAppointmentButton,
  TagContainer,
} from "./AppointmentsTable.styles";
import { appointmentsTableColumns } from "./appointmentsTableColumns";
import { DeleteAppointmentModal } from "./components/DeleteAppointmentModal";

type AppointmentsTableProps = {
  appointments?: IAppointment[];
  isLoading: boolean;
  onAppointmentDelete: (
    notification?: Notification,
    refetchTable?: boolean
  ) => void;
};

export function AppointmentsTable({
  appointments,
  isLoading,
  onAppointmentDelete,
}: AppointmentsTableProps) {
  const [isDeleteAppointmentModalOpen, setIsDeleteAppointmentModalOpen] =
    useState(false);
  const [appointmentToDelete, setAppointmentToDelete] =
    useState<IAppointment>();

  const parsedAppointments = useMemo(() => {
    if (!appointments) return [];

    const handleOnClick = (appointment: IAppointment) => {
      window.open(appointment.appointmentUrl, "_blank");
    };

    const handleAction = (appointment: IAppointment) => {
      const currentDate = dayjs();
      const appointmentDate = dayjs(appointment.date);

      if (
        currentDate >=
        appointmentDate.add(appointment.durationInMinutes, "minute")
      ) {
        return (
          <TagContainer>
            <Tag variant="info">Past meeting</Tag>
          </TagContainer>
        );
      }

      if (currentDate < appointmentDate.add(-5, "minute")) {
        return (
          <Tooltip
            title="You can join the appointment from here 5 mins before it is due to start."
            zIndex={3002}
            color="#4B4B4B"
            arrow={false}
            styles={{
              body: {
                minHeight: "40px",
                minWidth: "220px",
                borderRadius: "4px !important",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              },
            }}
          >
            <TagContainer>
              <Button
                label="Join appointment"
                type="button"
                size="small"
                onClick={() => handleOnClick(appointment)}
                disabled
              />
            </TagContainer>
          </Tooltip>
        );
      }

      return (
        <TagContainer>
          <Button
            label="Join appointment"
            type="button"
            size="small"
            onClick={() => {
              handleOnClick(appointment);
              mixpanelActions.track("User Action: JoinAppointmentButton");
            }}
          />
        </TagContainer>
      );
    };

    const handleDeleteAppointment = (appointment: IAppointment) => {
      setIsDeleteAppointmentModalOpen(true);
      setAppointmentToDelete(appointment);
    };

    return appointments.map((appointment: IAppointment) => {
      return {
        id: appointment.id,
        title: appointment.title,
        date: appointment.date,
        duration: `${appointment.durationInMinutes} min`,
        appointmentStatus: handleAction(appointment),
        deleteAppointment: (
          <DeleteAppointmentButton
            role="button"
            onClick={() => {
              handleDeleteAppointment(appointment);
              mixpanelActions.track("User Action: CancelAppointmentButton");
            }}
            onKeyDown={() => handleDeleteAppointment(appointment)}
            tabIndex={-1}
            className="actionContainer"
          >
            <Delete />
          </DeleteAppointmentButton>
        ),
      } as IAppointmentTableData;
    });
  }, [appointments]);

  const handleOnModaClose = async (confirm: boolean) => {
    if (confirm && appointmentToDelete?.id) {
      const result = await appointmentsService.deleteAppointment(
        appointmentToDelete?.id
      );

      const notification: Notification = {
        show: true,
        message: "",
        type: "success",
        width: "max-content",
      };

      let refetchTable = false;

      if (result.status >= 200 && result.status < 300) {
        notification.message = "Appointment successfully canceled";
        refetchTable = true;
        onAppointmentDelete(notification, refetchTable);
      } else {
        notification.message =
          "Error canceling the appointment, please try again";
        notification.type = "error";
        onAppointmentDelete(notification, refetchTable);
      }
    }

    setIsDeleteAppointmentModalOpen(false);
    setAppointmentToDelete(undefined);
  };

  if (isLoading) {
    return <TableSkeleton columnsNumber={5} rowsNumber={6} />;
  }

  return (
    <>
      <AppointmentsContainer>
        <Table data={parsedAppointments} columns={appointmentsTableColumns} />
      </AppointmentsContainer>

      {isDeleteAppointmentModalOpen ? (
        <DeleteAppointmentModal onClose={handleOnModaClose} />
      ) : null}
    </>
  );
}
