import { Select } from "antd";
import { useEffect, useRef, useState } from "react";

import { MagnifyingGlass } from "../../assets/icons/MagnifyingGlass";
import { css } from "../../styles";
import { IOption } from "../SelectDropdown";

type SearchFieldProps = {
  value?: string | null;
  options: IOption[];
  placeholder?: string;
  onValueChange: (selected: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

const searchFieldInactiveCss = css({
  fontFamily: "$openSans !important",
  backgroundColor: "rgba(232, 228, 228, 0.3464) !important",
  border: "2px solid rgba(232, 228, 228, 0) !important",
  borderRadius: "21px",
  color: "#212121 !important",
  cursor: "pointer !important",

  ".ant-select-arrow": {
    position: "absolute",
    top: "19px",
    left: "10px",
  },

  ".ant-select-selector": {
    fontFamily: "$openSans !important",
    paddingLeft: "40px !important",
    cursor: "pointer !important",
  },

  ".ant-select-selection-search": {
    fontFamily: "$openSans !important",
    cursor: "pointer !important",
  },

  ".ant-select-selection-search-input": {
    cursor: "pointer !important",
  },

  "&:hover": {
    backgroundColor: "rgba(244, 244, 244, 1) !important",
    border: "2px solid rgba(244, 244, 244, 1) !important",
  },
});

const searchFieldActiveCss = css({
  fontFamily: "$openSans !important",
  backgroundColor: "#FFFFFF",
  border: "2px solid #9182C6",
  borderRadius: "21px",
  color: "#212121",

  ".ant-select-arrow": {
    position: "absolute",
    top: "19px",
    left: "10px",
  },

  ".ant-select-selector": {
    fontFamily: "$openSans !important",
    paddingLeft: "40px !important",
  },

  ".ant-select-selection-search": {
    fontFamily: "$openSans !important",
  },
});

const popupCss = css({
  fontFamily: "$openSans !important",
  zIndex: "2000 !important",

  ".ant-select-item-empty": {
    color: "#666666 !important",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "$openSans !important",
    padding: "4px 0 4px 36px",
  },

  ".ant-select-item-option-content": {
    color: "#212121 !important",
  },
});

export function SearchField({
  value,
  placeholder = "Search",
  options,
  onBlur,
  onFocus,
  onValueChange,
}: SearchFieldProps) {
  const [isActive, setIsActive] = useState(false);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [visibleOptions, setVisibleOptions] = useState<IOption[]>(options);

  const searchFieldRef = useRef(null);

  useEffect(() => {
    setVisibleOptions(options);
  }, [options]);

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue);
    const filteredOptions = options.filter((option) =>
      `${option?.value} ${option?.label}`
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    );

    setVisibleOptions(filteredOptions);
  };

  const handleFocus = () => {
    setIsActive(true);
    if (onFocus) onFocus();
  };

  const handleBlur = () => {
    setIsActive(false);
    setSearchValue(null);
    if (onBlur) onBlur();
  };

  const handleOnChange = (value: string) => {
    setSearchValue(null);

    if (searchFieldRef.current) {
      (searchFieldRef.current as HTMLElement).blur();
    }

    onValueChange(value);
  };

  return (
    <Select
      showSearch
      value={value}
      suffixIcon={<MagnifyingGlass />}
      style={{ width: "100%", height: "40px" }}
      placeholder={placeholder}
      notFoundContent="No results found"
      options={visibleOptions}
      variant="borderless"
      defaultActiveFirstOption={false}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onSearch={handleSearch}
      onChange={handleOnChange}
      listHeight={160}
      className={!isActive ? searchFieldInactiveCss() : searchFieldActiveCss()}
      popupClassName={popupCss()}
      filterOption={(input, option) =>
        `${option?.value} ${option?.label}`
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      ref={searchFieldRef}
      open={searchValue !== null && searchValue.length > 0}
    />
  );
}
