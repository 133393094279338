import { SourceLegends } from "../@types/Source";

export const sourceLegends: SourceLegends = {
  "a&d_ua-651ble": {
    name: "A&D 651 BLE",
    systolicColor: "#7562A8",
    diastolicColor: "#C87C36",
    shape: "circle",
    lineOpacity: 0.38,
  },
  "apple-health": {
    name: "Apple Health",
    color: "#7562A8",
    shape: "line",
  },
  "bodytrace-bp": {
    name: "Bodytrace",
    systolicColor: "#6697F4",
    diastolicColor: "#BE8AAD",
    color: "#7562A8",
    shape: "circle",
    lineOpacity: 0.38,
  },
  "bodytrace-scale": {
    name: "Bodytrace Scale",
    color: "#7562A8",
    shape: "circle",
    lineOpacity: 0.38,
  },
  fitbit: {
    name: "Fitbit",
    color: "#7562A8",
    shape: "line",
    lineOpacity: 0.38,
  },
  "mir-smart-one": {
    name: "MIR Smart One",
    color: "#C87C36",
    shape: "circle",
    lineOpacity: 0.38,
    isDotClickable: true,
  },
  "mir-spirobank-smart": {
    name: "MIR Spirobank Smart",
    color: "#C87C36",
    shape: "circle",
    lineOpacity: 0.38,
    isDotClickable: true,
  },
  "mir-unknown-spirometer": {
    name: "MIR Unknown Spirometer",
    color: "#C87C36",
    shape: "circle",
    lineOpacity: 0.38,
    isDotClickable: true,
  },
  "monitoring-portal": {
    name: "Portal",
    color: "#666666",
    shape: "square",
    lineOpacity: 0.38,
  },
  "load-data": {
    name: "Unknown",
    color: "#7562A8",
    shape: "circle",
    lineOpacity: 0.38,
  },
  "nonin-3230": {
    name: "Nonin 3220",
    color: "#C87C36",
    shape: "circle",
    lineOpacity: 0.38,
  },
  "pmp-app-manual": {
    name: "App manual",
    color: "#297A94",
    shape: "triangle",
    lineOpacity: 0.38,
  },
  withings: {
    name: "Withings",
    color: "#7562A8",
    shape: "line",
  },
  "withings-bp-auto": {
    name: "Withings BP Auto",
    color: "#7562A8",
    shape: "line",
  },
  "withings-device": {
    name: "Withings Device",
    color: "#7562A8",
    shape: "line",
  },
  "withings-device-group": {
    name: "Withings Device Group",
    color: "#7562A8",
    shape: "line",
  },
  "withings-manual": {
    name: "Withings Manual",
    color: "#7562A8",
    shape: "line",
  },
  aiocare: {
    name: "AioCare",
    color: "#2196f3",
    shape: "circle",
    lineOpacity: 0.38,
  },
  unknown: {
    name: "Unknown",
    color: "#7562A8",
    shape: "circle",
    lineOpacity: 0.38,
  },
  archived: {
    name: "",
    color: "#939393",
    shape: "circle",
  },
  fibricheck: {
    name: "Fibricheck",
    color: "#2196f3",
    shape: "circle",
    lineOpacity: 0.38,
  },
  emed: {
    name: "Emed",
    color: "#2196f3",
    shape: "circle",
    lineOpacity: 0.38,
  },
  "mighty-sat-rx": {
    name: "Mighty Sat Rx",
    color: "#2196f3",
    shape: "circle",
    lineOpacity: 0.38,
  },
};
