import { useQuery } from "@tanstack/react-query";

import {
  IArticlesTableResponse,
  IRelatedArticles,
} from "../../@types/Articles";
import { articlesSerivce } from "../../services/articlesService";

export function useArticles(hospitalGroupId?: number) {
  const { data, isError, isFetching, refetch, isRefetching } = useQuery<
    IArticlesTableResponse[]
  >({
    queryKey: [`articles-list-${hospitalGroupId}`],
    queryFn: async () => {
      const { data } = await articlesSerivce.gethospitalArticles(
        hospitalGroupId
      );

      return data;
    },
    refetchOnMount: true,
  });

  return {
    articles: data,
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}

export function useHospitalArticles() {
  const { data, isError, isFetching, refetch, isRefetching } = useQuery<
    IRelatedArticles[]
  >({
    queryKey: [`hospital-articles-list`],
    queryFn: async () => {
      const { data } = await articlesSerivce.getAllHospitalArticles();

      return data;
    },
    refetchOnMount: true,
  });

  return {
    articles: data,
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}
