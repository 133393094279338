import dayjs from "dayjs";
import { useState } from "react";

import { MeasurementTypes } from "../../../../@types/Measurements";
import { Notification } from "../../../../@types/Notification";
import { ISectionProps } from "../../../../@types/Patient";
import { ChevronDown } from "../../../../assets/icons/ChevronDown";
import { ChevronUp } from "../../../../assets/icons/ChevronUp";
import { CustomIconButton } from "../../../../components/CustomIconButton";
import { ToastNotification } from "../../../../components/ToastNotification";
import { useMeasurementConfigs } from "../../../../hooks/queries/measurement-configs";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useModal } from "../../../../hooks/useModal";
import { useUserPreferences } from "../../../../hooks/useUserPreferences";
import { PageTitle } from "../../../../layout/components/PageTitle";
import { mixpanelActions } from "../../../../utils/mixpanel";
import {
  ChartsContainer,
  MobilePatientPageTitleContainer,
} from "../../PatientPage.styles";
import { AddMeasurementModal } from "../AddMeasurementModal";
import { MeasurementCard } from "../MeasurementCard";
import { AddMeasurementButton } from "../SpirometrySection/SpirometrySection.styles";
import { NewDataCOntainer, TitleContainer } from "./LabsSection.styles";

export function LabsSection({
  patient,
  updateColapsedSections,
  collapsedSection,
}: ISectionProps) {
  const { portalPreferences } = useUserPreferences();
  const { isMobile } = useIsMobile();
  const { openModal, closeModal } = useModal();

  const { configs } = useMeasurementConfigs({
    patientId: patient?.id,
    section: "labResults",
  });

  const [notification, setNotification] = useState<Notification>({
    show: false,
    message: "",
    type: "error",
    width: undefined,
    rightMargin: "25%",
  });

  const [showNewData, setShowNewData] = useState<boolean>(false);

  const handleOnModalClose = (notification?: Notification) => {
    if (notification !== undefined) {
      setNotification(notification);

      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    closeModal();
  };

  const handleAddLabResult = () => {
    mixpanelActions.track(`User action: Add Measurement Button`);
    openModal(
      <AddMeasurementModal
        section="lab_result"
        patientId={patient?.id.toString()}
        onClose={handleOnModalClose}
      />,
      {
        width: isMobile ? "98vw" : "620px",
        height: isMobile ? undefined : "auto",
        borderRadius: isMobile ? "8px" : undefined,
        showCloseButton: true,
      }
    );
  };

  const checkNewData = (mostRecentDate: dayjs.Dayjs) => {
    if (showNewData === false) {
      const collapsedDate = dayjs(collapsedSection.collapsedDate);
      if (mostRecentDate > collapsedDate) {
        setShowNewData(true);
      }
    }
  };

  const handleConfigs = () => {
    if (configs === null || configs.length === 0 || (configs as any).errors)
      return null;

    const configToReturn: any[] = [];

    configs
      .filter(
        (measurement) =>
          measurement.type !== portalPreferences.pinnedMeasurementType
      )
      .forEach((measurement, index) =>
        configToReturn.push(
          <MeasurementCard
            key={`${measurement.title}_${measurement.type}_${index}`}
            cardTitle={`${measurement.title} ${
              measurement.unit ? `(${measurement.unit})` : ""
            }`}
            unit={measurement.unit}
            measurementType={measurement.type as MeasurementTypes}
            minValue={measurement.min}
            maxValue={measurement.max}
            checkNewData={checkNewData}
          />
        )
      );

    return configToReturn;
  };

  const handleCollapseSection = () => {
    updateColapsedSections("labsSection");
    if (collapsedSection.value === true) {
      setShowNewData(false);
    }
  };

  return (
    <>
      {!isMobile && (
        <TitleContainer>
          <PageTitle>Lab results</PageTitle>
          {showNewData && <NewDataCOntainer>(new data)</NewDataCOntainer>}
          <CustomIconButton
            style={{ marginLeft: "2px" }}
            onClick={handleCollapseSection}
          >
            {collapsedSection.value ? <ChevronUp /> : <ChevronDown />}
          </CustomIconButton>
        </TitleContainer>
      )}

      <ChartsContainer
        style={{
          height: collapsedSection.value ? "0" : "",
          overflow: collapsedSection.value ? "hidden" : "",
        }}
      >
        <AddMeasurementButton onClick={handleAddLabResult}>
          Add lab
        </AddMeasurementButton>
        {isMobile && (
          <MobilePatientPageTitleContainer>
            <PageTitle>Lab results</PageTitle>
          </MobilePatientPageTitleContainer>
        )}

        {handleConfigs()}
      </ChartsContainer>
      {notification.show ? (
        <ToastNotification
          message={notification.message}
          type={notification.type}
          width={notification.width}
          rightMargin="25%"
        />
      ) : null}
    </>
  );
}
