import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";

import {
  BillingPatientTableContent,
  IBillablePatient,
} from "../../../../@types/Patient";
import { TableSkeleton } from "../../../../components/TableSkeleton";
import { TableSorted } from "../../../../components/TableSorted";

type PatientListTableProps = {
  content: BillingPatientTableContent[];
  isLoading?: boolean;
  columns: ColumnDef<any, string>[];
};

export function BillingTable({
  content,
  isLoading = false,
  columns,
}: PatientListTableProps) {
  const navigate = useNavigate();

  const handleRowClick = (patientContent: IBillablePatient) => {
    navigate(`/patients/${patientContent.pmpId}`);
  };

  const emptyStateText = "No patients with data recorded with smart devices";

  if (isLoading) {
    return <TableSkeleton columnsNumber={8} />;
  }

  return (
    <TableSorted
      data={content}
      onRowClick={handleRowClick}
      emptyStateText={emptyStateText}
      columns={columns}
    />
  );
}
