import { useQuery } from "@tanstack/react-query";

import { IProtocolResponse } from "../../@types/Protocol";
import { protocolService } from "../../services/protocol.service";

export function useProtocols() {
  const { data, isError, isFetching, refetch, isRefetching } = useQuery<
    IProtocolResponse[]
  >({
    queryKey: ["protocols-list"],
    queryFn: async () => {
      const { data } = await protocolService.getProtocolList();

      return data;
    },
    refetchOnMount: "always",
  });

  return {
    protocols: data as IProtocolResponse[],
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}
