import { useQuery } from "@tanstack/react-query";

import { BlowAcceptabilityProps } from "../../pages/PatientPage/components/MeasurementModal/components/BlowAcceptabilitySection";
import { spirometryService } from "../../services/spirometryService";

export function useArtiQSpirometryData(spirometryId: number[]) {
  const { data, isError, isFetching } = useQuery({
    queryKey: ["spirometry-artiq-data", spirometryId],
    queryFn: async () => {
      const { data } = await spirometryService.getArtiqSessionData(
        spirometryId[0]
      );

      const blowAcceptability = <BlowAcceptabilityProps[]>[];
      const flowVolumeAxes = <any>[];
      const timeVolumeAxes = <any>[];

      data.spirometryTests.forEach((spirometryTest: any) => {
        const fvcAcceptabilityReasonParsed =
          spirometryTest.fvc_acceptability_reason &&
          spirometryTest.fvc_acceptability_reason.split(";");

        blowAcceptability.push({
          fvcAcceptabilityReason: fvcAcceptabilityReasonParsed,
          fvcAcceptability: spirometryTest.fvc_acceptability,
          fev1Acceptability: spirometryTest.fev1_acceptability,
        });
        flowVolumeAxes.push(spirometryTest.flow_volume_axes);
        timeVolumeAxes.push(spirometryTest.time_volume_axes);
      });

      const parsedArtiQSpirometryData = {
        blowAcceptability,

        fev1Grade: undefined,
        fvcGrade: undefined,

        flowVolumeAxes,
        timeVolumeAxes,

        bestValues: {
          fev1: data.bestOfSession.fev1,
          predictedFev1: data.bestOfSession.percentPredictedFev1,
          fvc: data.bestOfSession.fvc,
          predictedFvc: data.bestOfSession.percentPredictedFvc,
          fev1fvc: `${Math.round(data.bestOfSession.fev1Fvc * 100)}%`,
          pef: data.bestOfSession.pef,
        },

        percentPredictedWarning: data.percentPredictedWarning,
      };

      return parsedArtiQSpirometryData;
    },
    refetchOnMount: "always",
    enabled: !!spirometryId,
  });

  return { artiQData: data, isError, isFetching };
}
