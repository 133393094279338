import { Spin } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";

import { ILogin } from "../../@types/Login";
import pmpLogoName from "../../assets/images/pmp-logo-name.png";
import { Button } from "../../components/Button";
import { TextField } from "../../components/TextField";
import {
  IS_MOBILE_APP,
  VIEWED_FIRST_PATIENT,
} from "../../constants/localStorageKeys";
import { useAuth } from "../../hooks/useAuth";
import {
  FooterLinksMobile,
  FooterLinksDesktop,
} from "../../layout/components/FooterLinks";
import { mixpanelActions } from "../../utils/mixpanel";
import { spinCss } from "../Surveys/Answer Survey/Components/Survey/AnswerSurvey.styles";
import {
  Logo,
  Container,
  StyledTile,
  ContentContainer,
  emailTextFieldStyles,
  passwordTextFieldStyles,
  LoadingOverlay,
  loginButtonStyles,
  ErrorMessage,
  InfoMessage,
} from "./LoginPage.styles";

export function LoginPage() {
  document.title = "Login - patientMpower";

  const [apiFieldErros, setApiFieldErrors] = useState({
    email: "",
    password: "",
  });
  const { authenticate, isAuthenticated, setLoginDetails, setMfaDetails } =
    useAuth();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [globalError, setGlobalError] = useState("");
  const [capsLockEnabled, setCapsLockEnabled] = useState(false);
  const [showSetPasswordSuccessMessage, setShowSetPasswordSuccessMessage] =
    useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      const isMobileApp = localStorage.getItem(IS_MOBILE_APP);
      if (!isMobileApp) {
        navigate("/patients");
      }

      navigate("/patients");
    }

    const showMessage = Boolean(searchParams.get("showMessage"));
    setShowSetPasswordSuccessMessage(showMessage);
  }, [isAuthenticated]);

  const formValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address.")
      .required("Please enter your email address."),
    password: yup.string().required("Please enter your password."),
  });

  const handleOnSubmitForm = async ({ email, password }: ILogin) => {
    setIsLoading(true);

    const responseFromAPI = await authenticate({
      email,
      password,
    });

    const { errors } = responseFromAPI;

    if (errors) {
      setIsLoading(false);
      setGlobalError(errors[0].message);
      return;
    }

    setLoginDetails({
      email,
      password,
    });

    if (
      responseFromAPI.mfaDetails !== null &&
      responseFromAPI.mfaDetails !== undefined
    ) {
      setIsLoading(false);

      responseFromAPI.mfaDetails.email = email;
      setMfaDetails(responseFromAPI.mfaDetails);

      mixpanelActions.track("Visited Screen: MFALoginPage");
      navigate("/mfa");
    } else {
      setIsLoading(false);
      localStorage.setItem(VIEWED_FIRST_PATIENT, "no");
      navigate("/patients");
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: handleOnSubmitForm,
    validationSchema: formValidationSchema,
  });

  const shouldShowErrorMessage = (field: "email" | "password") => {
    return formik.touched[field]
      ? apiFieldErros[field] || formik.errors[field]
      : "";
  };

  const handleOnKeyUp = (event: any) => {
    const capsEnabled =
      (event.getModifierState && event.getModifierState("CapsLock")) || false;

    setCapsLockEnabled(capsEnabled);

    if (event.key === "Enter") {
      formik.submitForm();
    }
  };

  const handleResetGlobalErrorFieldOnBlur = (field: "email" | "password") => {
    setApiFieldErrors((state: ILogin) => ({ ...state, [field]: "" }));
  };

  const handleOnClickOverForgotPassword = () => {
    mixpanelActions.track("User Action: ClickForgotPassword");
    navigate("/forgot-password");
  };

  const isMobileApp = localStorage.getItem(IS_MOBILE_APP);
  if (isMobileApp) {
    return <Spin className={spinCss()} fullscreen size="large" />;
  }

  return (
    <Container>
      <StyledTile>
        {isLoading && <LoadingOverlay />}

        <ContentContainer>
          <Logo src={pmpLogoName} alt="patientMpower logo" />
          {showSetPasswordSuccessMessage && (
            <InfoMessage>Password successfully reset, login below!</InfoMessage>
          )}

          <div>
            <TextField
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder="jane@example.com"
              className={emailTextFieldStyles()}
              errorMessage={shouldShowErrorMessage("email")}
              onBlur={() => handleResetGlobalErrorFieldOnBlur("email")}
            />

            <TextField
              isPassword
              name="password"
              label="Password"
              placeholder="●●●●●●●●●●●●"
              value={formik.values.password}
              onChange={formik.handleChange}
              className={passwordTextFieldStyles()}
              auxiliarButtonLabel="Forgot password?"
              onKeyDown={handleOnKeyUp}
              errorMessage={shouldShowErrorMessage("password")}
              onClickAuxiliarButton={handleOnClickOverForgotPassword}
              onBlur={() => handleResetGlobalErrorFieldOnBlur("password")}
              warningMessage={capsLockEnabled ? "Caps lock is on." : undefined}
            />

            {globalError && <ErrorMessage>{globalError}</ErrorMessage>}
          </div>

          <Button
            type="button"
            label="Log in"
            isLoading={isLoading}
            className={loginButtonStyles()}
            onClick={formik.submitForm}
          />
        </ContentContainer>
        <FooterLinksMobile />
      </StyledTile>
      <FooterLinksDesktop />
    </Container>
  );
}
