import { useQuery } from "@tanstack/react-query";

import { MeasurementsConfig } from "../../@types/Measurements";
import { measurementsService } from "../../services/measurementsService";

export function useMeasurementConfigs({
  patientId,
  section,
}: {
  patientId: number | undefined;
  section: string | undefined;
}) {
  const { data, isError, isFetching } = useQuery({
    queryKey: [`${section}-measurement-configs-${patientId}`, patientId],
    queryFn: async () => {
      if (!patientId || !section) return [] as MeasurementsConfig[];

      const { data } = await measurementsService.getPatientMeasurementConfigs(
        patientId,
        section
      );

      return data;
    },
  });

  return {
    configs: (data ?? []) as MeasurementsConfig[],
    isFetching,
    isError,
  };
}

export function useMeasurementTypeConfig({
  measurementType,
}: {
  measurementType: string;
}) {
  const { data, isError, isFetching } = useQuery<MeasurementsConfig>({
    queryKey: [`measurement-type-config-${measurementType}`],
    queryFn: async () => {
      const { data } = await measurementsService.getMeasurementTypeConfig(
        measurementType
      );

      return data;
    },
  });

  return {
    config: data as MeasurementsConfig,
    isFetching,
    isError,
  };
}
