import * as PopoverPrimitive from "@radix-ui/react-popover";
import { useState, useEffect } from "react";

import { ArrowDown } from "../../assets/icons/ArrowDown";
import { CircleLetter } from "../../assets/icons/CircleLetter";
import { CircleLetterOuter } from "../../assets/icons/CircleLetterOuter";
import Dummy from "../../assets/images/dummy.png";
import { AUTH_USER_DETAILS } from "../../constants/localStorageKeys";
import { useAuth } from "../../hooks/useAuth";
import {
  StyledContent,
  PopoverOption,
  IconButton,
  PopoverContainer,
  UserSettingsMobileContainer,
  ProfileInfo,
} from "./UserSettings.style";

const Popover = PopoverPrimitive.Root;

function PopoverContent({ children, ...props }: any) {
  return (
    <PopoverPrimitive.Portal>
      <StyledContent {...props}>{children}</StyledContent>
    </PopoverPrimitive.Portal>
  );
}

export function UserSettingsDesktop() {
  const { signOut } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userFirstLetter, setUserFirstLetter] = useState("");

  const userDetails = localStorage.getItem(AUTH_USER_DETAILS);

  useEffect(() => {
    let userDetailsParsed = null;
    if (userDetails !== null) {
      try {
        userDetailsParsed = userDetails && JSON.parse(userDetails);
        setUserEmail(userDetailsParsed.userEmail);
        if (userDetailsParsed.userFirstName) {
          const firstLetter = userDetailsParsed.userFirstName
            .charAt(0)
            .toUpperCase();
          setUserFirstLetter(firstLetter);
        } else {
          const firstLetter = userDetailsParsed.userEmail
            .charAt(0)
            .toUpperCase();
          setUserFirstLetter(firstLetter);
        }
      } catch (error) {
        console.error(error);
        setUserFirstLetter("");
      }
    }
  }, [userDetails]);

  const handleSignOutClick = () => {
    setIsMenuOpen((prevState) => !prevState);
    signOut();
  };

  const handleSettingsClick = () => {
    setIsMenuOpen((prevState) => !prevState);
    window.location.assign("/settings");
  };

  return (
    <PopoverContainer>
      <Popover
        open={isMenuOpen}
        onOpenChange={(open: boolean) => setIsMenuOpen(open)}
      >
        <PopoverPrimitive.Trigger tabIndex={0} asChild>
          <IconButton aria-label="User settings">
            <>
              {userFirstLetter !== "" ? (
                <CircleLetter letter={userFirstLetter} />
              ) : (
                <img src={Dummy} alt="profile" />
              )}
              <ArrowDown />
            </>
          </IconButton>
        </PopoverPrimitive.Trigger>
        <PopoverContent>
          {userFirstLetter !== "" && (
            <ProfileInfo>
              <CircleLetterOuter letter={userFirstLetter} />
              <div>{userEmail}</div>
            </ProfileInfo>
          )}
          <PopoverOption onClick={handleSettingsClick}>Settings</PopoverOption>
          <PopoverOption onClick={handleSignOutClick}>Sign out</PopoverOption>
        </PopoverContent>
      </Popover>
    </PopoverContainer>
  );
}

type IUserSettingsMobileProps = {
  onClick?: () => void;
  imgSource?: string;
};

export function MobileAvatar({
  onClick,
  imgSource = Dummy,
}: IUserSettingsMobileProps) {
  const [userFirstLetter, setUserFirstLetter] = useState("");

  const userDetails = localStorage.getItem(AUTH_USER_DETAILS);
  useEffect(() => {
    let userDetailsParsed = null;
    if (userDetails !== null) {
      try {
        userDetailsParsed = userDetails && JSON.parse(userDetails);
        if (userDetailsParsed.userFirstName) {
          const firstLetter = userDetailsParsed.userFirstName
            .charAt(0)
            .toUpperCase();
          setUserFirstLetter(firstLetter);
        } else {
          const firstLetter = userDetailsParsed.userEmail
            .charAt(0)
            .toUpperCase();
          setUserFirstLetter(firstLetter);
        }
      } catch (error) {
        console.error(error);
        setUserFirstLetter("");
      }
    }
  }, [userDetails]);

  return (
    <UserSettingsMobileContainer type="button" onClick={onClick}>
      {userFirstLetter !== "" ? (
        <CircleLetter letter={userFirstLetter} />
      ) : (
        <img src={imgSource} alt="Profile" />
      )}
      <p>Profile</p>
    </UserSettingsMobileContainer>
  );
}
