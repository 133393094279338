import { useQuery } from "@tanstack/react-query";

import { IDevice } from "../../@types/Devices";
import { devicesService } from "../../services/devicesService";

export function useDevices(patientId: number) {
  const { data, isError, isFetching, refetch, isRefetching } = useQuery<
    IDevice[]
  >({
    queryKey: [`devices-list-${patientId}`, patientId],
    queryFn: async () => {
      const { data } = await devicesService.getAllDevices(patientId);

      return data;
    },
    refetchOnMount: true,
  });

  return {
    devices: data,
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}
