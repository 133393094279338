import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";

import { IPatient, PatientListTableContent } from "../../../../@types/Patient";
import { TableSkeleton } from "../../../../components/TableSkeleton";
import { TableSorted } from "../../../../components/TableSorted";
import { patientListVariants } from "../../../../constants/patientListVariants";
import useIsMobile from "../../../../hooks/useIsMobile";
import { MobileList } from "./components/MobileList";

type PatientListTableProps = {
  content: PatientListTableContent[];
  isLoading?: boolean;
  variant: string;
  columns: ColumnDef<any, string>[];
};

export function PatientListSortedTable({
  content,
  variant,
  isLoading = false,
  columns,
}: PatientListTableProps) {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();

  const handleRowClick = (patientContent: IPatient) => {
    navigate(
      `/patients/${patientContent.id}/${
        variant === patientListVariants.ALERTS ? "alerts" : "overview"
      }`
    );
  };

  const emptyStateText =
    variant === patientListVariants.ALERTS
      ? "No patients with alerts"
      : `No patients ${variant}`;

  if (isLoading) {
    return <TableSkeleton columnsNumber={8} />;
  }

  if (isMobile) {
    return (
      <MobileList
        onRowClick={handleRowClick}
        emptyStateText={emptyStateText}
        data={content}
      />
    );
  }

  return (
    <TableSorted
      data={content}
      onRowClick={handleRowClick}
      emptyStateText={emptyStateText}
      columns={columns}
    />
  );
}
