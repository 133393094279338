import { ReactNode } from "react";

import { Container, PageContainer } from "./PageLayout.styles";

type IPageLayoutProps = {
  children: ReactNode;
  className?: string;
  extraPadding?: boolean;
};

export function PageLayout({
  children,
  className = "",
  extraPadding = false,
}: IPageLayoutProps) {
  return (
    <Container className={className} extraPadding={extraPadding}>
      <PageContainer className="page-container">{children}</PageContainer>
    </Container>
  );
}
