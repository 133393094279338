import { ColumnDef } from "@tanstack/react-table";

export const promsTableColumns: ColumnDef<any, string>[] = [
  {
    accessorKey: "name",
    header: () => "Name",
    cell: (info) => info.renderValue(),
    size: 200,
    sortingFn: "text",
  },
  {
    accessorKey: "lastCompleted",
    header: () => "Last completed",
    cell: (info) => info.renderValue(),
    size: 100,
    sortingFn: (rowA, rowB) => {
      return rowB.original.lastCompletedDate - rowA.original.lastCompletedDate;
    },
  },
  {
    accessorKey: "frequency",
    header: () => "Frequency",
    cell: (info) => info.renderValue(),
    enableSorting: false,
    size: 200,
  },
  {
    accessorKey: "lastTotalScore",
    header: () => "Last Total Score",
    cell: (info) => info.renderValue(),
    size: 100,
    enableSorting: false,
  },
];
