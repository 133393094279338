import { styled, css } from "../../../../styles";

export const ModalContainer = styled("div", {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "24px 26px",

  "@sm": {
    height: "350px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "0",
  },
});

export const ModalTitle = styled("p", {
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "600",
  color: "#212121",

  "@sm": {
    marginBottom: "20px",
  },
});

export const ContentContainer = styled("div", {
  height: "auto",
  width: "auto",
  margin: "18px 0 32px 0",
  padding: "20px 20px 18px 20px",
  backgroundColor: "$gray-20",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
});

export const Row = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  margin: "5px 0",
  position: "relative",
  height: "30px",

  p: {
    width: "160px",
    fontSize: "14px",
    fontFamily: "$openSans",
    fontWeight: "600",
    lineHeight: "22px",
    color: "#757575",
  },

  variants: {
    dateField: {
      true: {
        height: "60px",
      },
    },
  },
});

export const StyledParagraph = styled("p", {
  color: "#4B4B4B !important",
  fontWeight: "400 !important",
});

export const ButtonContainer = styled("div", {
  height: "auto",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  paddingLeft: "10px",
});

export const CancelButton = styled("button", {
  height: "$13",
  padding: "0 $13",
  marginLeft: "5px",

  borderRadius: "20px",
  border: "2px solid $white",

  backgroundColor: "$white",

  gap: "$4",

  display: "flex",
  alignItems: "center",

  color: "$black",
  fontWeight: 600,
  lineHeight: "22px",
  fontSize: "$text-lg",
  fontFamily: "$openSans",

  cursor: "pointer",

  "&:focus": {
    outline: "none",
    border: "2px solid $primary-30",
  },

  "@sm": {
    width: "80px",
    height: "26px",
    fontSize: "12px",
    marginLeft: "40px",
    paddingLeft: "19px",
  },
});

export const dateInputCss = css({
  width: "55px",
  height: "100%",
  marginRight: "6px",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",

  span: {
    margin: "0px",
  },

  p: {
    width: "100% !important",
  },

  input: {
    border: "2px solid $gray-50",
    borderRadius: "6px",
    padding: "4px 9px",

    "&:hover": {
      border: "2px solid $primary-30",
    },
  },
});

export const ErrorMessage = styled("p", {
  position: "absolute",

  fontSize: "12px !important",
  fontWeight: "600 !important",

  color: "$error-light !important",

  width: "max-content !important",
  bottom: -14,
  left: 170,
});
