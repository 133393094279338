import { styled } from "../../../styles";
import { TextField } from "../../TextField";

export const DatePickerContainer = styled("div", {
  backgroundColor: "$white",

  boxSizing: "border-box",

  width: 269,
  padding: "$8 $11",
  borderRadius: "$6",
});

export const DatePickerTextField = styled(TextField, {
  width: "103px",

  span: {
    lineHeight: "10px",
    fontSize: "$text-sm",
    opacity: "1",
  },

  input: {
    fontSize: "$text-sm",
    opacity: "1",
  },
});

export const TextFieldsGroup = styled("div", {
  display: "flex",
  justifyContent: "space-between",

  variants: {
    singeDate: {
      true: {
        justifyContent: "center",
        textAlign: "center",
        span: {
          marginLeft: "0px",
        },
      },
    },
  },
});

export const DatePickerCalendar = styled("div", {
  width: 224,

  ".react-datepicker__day--selected": {
    borderRadius: "$round !important",
    backgroundColor: "$secondary",
    color: "$white !important",

    "&:hover": {
      fontWeight: "600 !important",
    },
  },

  ".react-datepicker": {
    border: "none",

    ".react-datepicker__navigation": {
      fontSize: "$text-sm",
      width: "5px",
      height: "10px",
      margin: "12px 5px 0",

      ".react-datepicker__navigation-icon": {
        "&:before": {
          scale: 0.6,
          borderColor: "$black",
        },
      },
    },
  },
  div: {
    fontSize: "$text-sm",
    fontFamily: "$openSans",

    ".react-datepicker__month-container": {
      width: 224,

      ".react-datepicker__header": {
        backgroundColor: "transparent",
        border: "none",
        paddingTop: 14,

        ".react-datepicker__day-names": {
          ".react-datepicker__day-name": {
            color: "$black",
            boxSizing: "border-box",
            height: "1.7rem",
            width: "1.7rem",
            paddingTop: 8,
            fontWeight: 600,
          },
        },

        ".react-datepicker__current-month": {
          color: "$black",
        },
      },

      ".react-datepicker__week": {
        ".react-datepicker__day--in-selecting-range": {
          "&:first-child": {
            borderTopLeftRadius: "50%",
            borderBottomLeftRadius: "50%",
          },
          "&:last-child": {
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
          },
        },
        ".react-datepicker__day--in-range": {
          "&:first-child": {
            borderTopLeftRadius: "50%",
            borderBottomLeftRadius: "50%",
          },
          "&:last-child": {
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
          },
        },
      },

      ".react-datepicker__month": {
        margin: "0 0.4rem 0 0",

        div: {
          ".react-datepicker__day--outside-month": {
            color: "$gray-80 !important",
          },

          ".react-datepicker__day": {
            color: "$black",
            boxSizing: "border-box",
            height: "1.9rem",
            width: "2rem",
            margin: "0.1rem 0",

            fontWeight: 400,
            border: "1px solid transparent",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "0",

            "&:not(.react-datepicker__day--in-range)": {
              "&:hover": {
                borderRadius: "$round",
                border: "1px solid $secondary-light",
              },
            },
          },

          ".react-datepicker__day--today": {
            color: "$secondary",
            backgroundColor: "transparent",
            fontWeight: 400,
            paddingLeft: 1,

            "&:before": {
              content: "•",
              position: "absolute",
              transform: "translateY(8px)",
            },
          },

          ".react-datepicker__day--keyboard-selected": {
            backgroundColor: "#E6F3F3",
            "&:not(.react-datepicker__day--in-range)": {
              borderRadius: "$round",
            },
          },

          ".react-datepicker__day--in-selecting-range": {
            color: "$black",
            backgroundColor: "$gray-40",
            borderRadius: "none",

            "&:not(.react-datepicker__day--in-range)": {
              "&:hover": {
                borderColor: "$secondary",
                backgroundColor: "$white",
                color: "$black !important",
                "&:before": {
                  content: " ",
                  height: "30px",
                  width: "15px",
                  position: "absolute",
                  backgroundImage:
                    "radial-gradient(circle at right, transparent 0, transparent 0.92rem, $gray-40 0.92rem)",
                  transform: "translateX(-9px)",
                  rotate: "180deg",
                },
              },
            },
          },

          ".react-datepicker__day--selecting-range-start": {
            borderRadius: "$round",
            color: "$white",
            backgroundColor: "$secondary",
          },

          ".react-datepicker__day--selecting-range-end": {
            "&:not(.react-datepicker__day--in-range), &:not(:first-child)": {
              "&:hover": {
                borderColor: "$secondary",
                backgroundColor: "$white",
                color: "$black !important",
                "&:before": {
                  content: " ",
                  height: "30px",
                  width: "15px",
                  position: "absolute",
                  backgroundImage:
                    "radial-gradient(circle at right, transparent 0, transparent 0.92rem, $gray-40 0.92rem)",
                  transform: "translateX(-9px)",
                  rotate: "0deg",
                },
              },
            },
          },

          ".react-datepicker__day--in-range": {
            color: "$black",
            backgroundColor: "$secondary-lightest",

            "&:hover": {
              fontWeight: 600,
            },
          },

          ".react-datepicker__day--range-start": {
            color: "$white",
            backgroundColor: "$secondary",
            borderRadius: "$round",

            "&:before": {
              content: " ",
              height: "30px",
              width: "15px",
              position: "absolute",
              backgroundImage:
                "radial-gradient(circle at right, transparent 0, transparent 0.92rem, $secondary-lightest 0.92rem)",
              transform: "translateX(-9px)",
              rotate: "180deg",
            },
          },

          ".react-datepicker__day--range-end": {
            color: "$white",
            backgroundColor: "$secondary",
            borderRadius: "$round",

            "&:before": {
              content: " ",
              height: "30px",
              width: "15px",
              position: "absolute",
              backgroundImage:
                "radial-gradient(circle at right, transparent 0, transparent 0.92rem, $secondary-lightest 0.92rem)",
              transform: "translateX(-9px)",
            },
          },
        },

        "&:hover": {
          ".react-datepicker__day--selecting-range-start": {
            border: "1px solid #seconday",
            "&:not(.react-datepicker__day--in-range)": {
              "&:before": {
                content: " ",
                height: "30px",
                width: "15px",
                position: "absolute",
                backgroundImage:
                  "radial-gradient(circle at right, transparent 0, transparent 0.92rem, $gray-40 0.92rem)",
                transform: "translateX(-9px)",
                rotate: "180deg",
              },
            },
          },
        },
      },
    },

    ".react-datepicker__time-name": {
      width: "1.4rem",
    },
  },
});
