import { AxiosResponse } from "axios";
import { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";

import { Notification } from "../../../../@types/Notification";
import { IBillablePatient } from "../../../../@types/Patient";
import { Button } from "../../../../components/Button";
import { DateTimePickerInput } from "../../../../components/DateTimePicker";
import { reportService } from "../../../../services/reportService";
import { mixpanelActions } from "../../../../utils/mixpanel";
import {
  ButtonContainer,
  CancelButton,
  ContentContainer,
  ErrorMessage,
  ModalContainer,
  ModalTitle,
  Row,
  StyledParagraph,
} from "./ReportExportModal.styles";

interface IBillingReportRequest {
  patientId?: number;
  startDate?: Dayjs;
  endDate?: Dayjs;
}

type ReportExportModalProps = {
  patient?: IBillablePatient;
  startDate?: Dayjs;
  endDate?: Dayjs;
  isBillingReport?: boolean;
  onClose: (notification?: Notification) => void;
};

export function ReportExportModal({
  patient,
  startDate,
  endDate,
  isBillingReport = false,
  onClose,
}: ReportExportModalProps) {
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);

  const handleDownloadFromServerResponse = (
    result: AxiosResponse<Blob, any>
  ) => {
    const contentDisposition = result.headers["content-disposition"];

    const fileName =
      contentDisposition?.match(/filename\*=UTF-8''(.+)/)?.[1] ||
      contentDisposition?.match(/filename=(.+);?/)?.[1] ||
      "Report.xlsx";

    const url = URL.createObjectURL(new Blob([result.data]));
    const link = Object.assign(document.createElement("a"), {
      href: url,
      download: fileName,
    });

    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleOnSubmitForm = async ({
    patientId,
    startDate,
    endDate,
  }: IBillingReportRequest) => {
    if (startDate === undefined || endDate === undefined) return;

    setIsDownloadingReport(true);

    const notification: Notification = {
      show: true,
      message: "",
      type: "success",
      width: "max-content",
    };

    if (isBillingReport) {
      mixpanelActions.track("User Action: Export billing report");
      const result = await reportService.downloadBillingReport(
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );

      if (result.status >= 200 && result.status < 300) {
        handleDownloadFromServerResponse(result);

        notification.message = "Report downloaded successfully.";
      } else {
        notification.type = "error";
        notification.message =
          "Something went wrnd while trying to downdload report, please try again.";
      }
    } else {
      mixpanelActions.track("User Action: Export patient report");
      if (patientId === undefined) return;

      const result = await reportService.downloadPatientReport(
        patientId,
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );

      if (result.status >= 200 && result.status < 300) {
        handleDownloadFromServerResponse(result);

        notification.message = "Report downloaded successfully.";
      } else {
        notification.type = "error";
        notification.message =
          "Something went wrong while trying to download report, please try again.";
      }
    }

    setIsDownloadingReport(false);
    onClose(notification);
  };

  const formValidationSchema = yup.object().shape({
    patientId: yup.number(),
    startDate: yup
      .date()
      .required("Start date is required.")
      .typeError("Start date must be a valid date."),
    endDate: yup
      .date()
      .required("End date is required.")
      .min(yup.ref("startDate"), "End date must be after the start date."),
  });

  const formik = useFormik<IBillingReportRequest>({
    initialValues: {
      patientId: patient?.pmpId,
      startDate,
      endDate,
    },
    onSubmit: handleOnSubmitForm,
    validationSchema: formValidationSchema,
  });

  const shouldShowErrorMessage = (field: "startDate" | "endDate") => {
    return formik.touched[field] ? formik.errors[field] : "";
  };

  return (
    <ModalContainer>
      <ModalTitle>
        Download {isBillingReport ? "billing" : "patient"} report
      </ModalTitle>
      <ContentContainer>
        <Row>
          <p>Format</p>
          <StyledParagraph>Excel</StyledParagraph>
        </Row>
        {patient !== undefined && !isBillingReport && (
          <Row>
            <p>Patient</p>
            <StyledParagraph>{patient.name}</StyledParagraph>
          </Row>
        )}
        <Row dateField>
          <p>Start date</p>
          <DateTimePickerInput
            showTime={false}
            format="YYYY-MM-DD"
            width="164px"
            defaultValue={formik.values.startDate}
            onChange={(value: Dayjs) =>
              formik.setFieldValue("startDate", value)
            }
          />
          <ErrorMessage>{shouldShowErrorMessage("startDate")}</ErrorMessage>
        </Row>
        <Row dateField>
          <p>End date</p>
          <DateTimePickerInput
            showTime={false}
            format="YYYY-MM-DD"
            width="164px"
            defaultValue={formik.values.endDate}
            onChange={(value: Dayjs) => formik.setFieldValue("endDate", value)}
          />
          <ErrorMessage>{shouldShowErrorMessage("endDate")}</ErrorMessage>
        </Row>
      </ContentContainer>
      <ButtonContainer>
        <Button
          onClick={formik.submitForm}
          type="submit"
          label="Download"
          isLoading={isDownloadingReport}
        />
        <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
      </ButtonContainer>
    </ModalContainer>
  );
}
