import { IArticleImage } from "../../../../../../@types/Articles";
import {
  BodyContainer,
  CancelButton,
  Header,
  ImageCard,
  ImageLibraryContainer,
  ImagesContainer,
} from "./ImageLibrary.styles";

export interface IImageLibraryProps {
  images: IArticleImage[];
  onClose: (selectedImage?: string) => void;
}

export function ImageLibrary({ images, onClose }: IImageLibraryProps) {
  const handleImages = () => {
    const imagesToDisplay: any[] = [];

    images.forEach((image) => {
      imagesToDisplay.push(
        <ImageCard key={image.key} onClick={() => onClose(image.key)}>
          <img src={image.preSignedUrl} alt={image.key} />
        </ImageCard>
      );
    });

    return imagesToDisplay;
  };

  return (
    <ImageLibraryContainer>
      <Header>
        <p>Choose an image</p>
        <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
      </Header>

      <BodyContainer>
        <ImagesContainer>{handleImages()}</ImagesContainer>
      </BodyContainer>
    </ImageLibraryContainer>
  );
}
