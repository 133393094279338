import { styled, css } from "../../../../../../styles";

export const ContentContainer = styled("div", {
  width: "100%",
  padding: "46px 68px 50px 68px",
  marginBottom: "46px",

  borderRadius: "8px",
  backgroundColor: "rgba(248, 248, 248, 0.80)",

  display: "flex",
  justifyContent: "space-evenly",
  gap: "48px",
});

export const Card = styled("div", {
  width: "375px",
  height: "100%",
  padding: "28px 20px 20px 20px",
  textAlign: "left",
  backgroundColor: "$white",

  borderRadius: "10px",

  position: "relative",
});

export const CardTitle = styled("p", {
  color: "$black",
  fontSize: "18px",
  fontWeight: "500",
  lineHeight: "24px",
});

export const CardContainer = styled("div", {
  width: "100%",
  height: "100%",

  borderRadius: "8px",
  border: "1px solid $gray-30",

  marginTop: "10px",
});

export const CardImageContainer = styled("div", {
  width: "100%",
  height: "180px",

  backgroundColor: "$gray-20",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const CardDetailsContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",

  padding: "12px 20px 28px 20px",
  backgroundColor: "$white",

  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
});

export const CardSubtitle = styled("p", {
  color: "$black",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "24px",
});

export const CardDescription = styled("p", {
  color: "rgba(33, 33, 33, 0.7)",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "18px",
});

export const CardContent = styled("div", {
  width: "300px",
  minHeight: "350px",

  marginTop: "12px",

  color: "rgba(33, 33, 33, 0.7)",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  wordWrap: "break-word",

  img: {
    width: "300px !important",
  },
});

export const IFrameContainer = styled("div", {
  height: "620px",

  variants: {
    isLoading: {
      true: {
        opacity: 0,
      },
    },
  },
});

// CSS

export const spinCss = css({
  backgroundColor: "white !important",
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 230,
  right: 175,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  ".ant-spin-dot": {
    ".ant-spin-dot-item": {
      backgroundColor: "#7562A8",
    },
  },
});
