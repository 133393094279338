import { styled, css } from "../../../../../styles";

// Components
export const FormInfoContainer = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  height: "100vh",
  padding: "20px",
  overflowY: "overlay",
});

export const FormTitle = styled("div", {
  width: "100%",

  display: "flex",
  flexDirection: "row",
  alignItems: "center",

  color: "$charcoal",
  marginBottom: "30px",

  fontSize: "18px",
  fontWeight: "600",
  lineHeight: "24px",
  fontFamily: "$openSans",
});

export const ProgressBarContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  width: "100%",
  marginBottom: "15px",
});

export const InfoContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

export const SurveyDescription = styled("div", {
  width: "100%",

  fontSize: "18px",
  fontWeight: "400",
  lineHeight: "28px",
  fontFamily: "$openSans",
});

export const FormAnswerContainer = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  backgroundColor: "white",
  width: "50vw",
  minHeight: "100vh",
  overflow: "hidden",

  "@md": {
    width: "80vw",
  },
  "@sm": {
    width: "100% !important",
  },
});

export const SubmitContainer = styled("div", {
  width: "100%",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

export const SectionIterationButton = styled("button", {
  height: "22px",
  width: "146px",

  marginBottom: "30px",

  border: "2px solid $white",

  backgroundColor: "$white",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "normal",
  fontFamily: "$openSans",
  color: "#4B4B4B",

  cursor: "pointer",
});

export const spinCss = css({
  backgroundColor: "rgba(0, 0, 0, 0.05) !important",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  ".ant-spin-dot": {
    ".ant-spin-dot-item": {
      backgroundColor: "#7562A8",
    },
  },
});

export const buttonCss = css({
  marginBottom: "15px",
  width: "100%",
  display: "flex",
  justifyContent: "center",

  button: {
    maxWidth: "300px",
    width: "100%",
    height: "40px",

    display: "flex",
    flexDirection: "row",
    justifyContent: "center",

    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "22px",
    fontFamily: "$openSans",
  },
});

export const buttonCssExtraMarginBottom = css(buttonCss, {
  marginBottom: "35px",
});

export const QuestionsAnswerContainer = styled("div", {
  position: "absolute",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "20px",
  height: "100vh",
  transition: "left 0.5s ease",
});

export const DisplayQuestion = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "calc(50vw - 20px)",
  marginBottom: "120px",
  margin: "0 10px 0 10px",
  height: "100vh",

  overflowY: "scroll",

  "@md": {
    width: "calc(80vw - 20px)",
  },

  "@sm": {
    width: "calc(100vw - 40px)",
    overflowY: "scroll",
  },

  "&::-webkit-scrollbar": {
    "-webkit-appearance": "none",
  },

  "&::-webkit-scrollbar:vertical": {
    width: "8px",
  },

  "&::-webkit-scrollbar:horizontal": {
    height: "8px",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0, 0, 0, .5)",
    borderRadius: "10px",
    border: "2px solid #ffffff",
  },

  "&::-webkit-scrollbar-track": {
    borderRadius: "10px",
    backgroundColor: "#ffffff",
  },
});
