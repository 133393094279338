import { useQuery } from "@tanstack/react-query";

import {
  IAppointment,
  IGetAppointmentsListRequest,
} from "../../@types/Appointment";
import { appointmentsService } from "../../services/appointmentsService";
import { getLongFormattedDate } from "../../utils/dateFormatter";

export function getUserAppointmentsData(request: IGetAppointmentsListRequest) {
  const { data, isFetching, isError, refetch, isRefetching } = useQuery<
    IAppointment[]
  >({
    queryKey: ["table-user-appointments"],
    queryFn: async () => {
      const { data } = await appointmentsService.getUserAppointments(request);

      return data;
    },
  });

  return {
    appointments: data?.map((appointmentData) => {
      return {
        ...appointmentData,
        date: getLongFormattedDate(appointmentData.date),
      } as IAppointment;
    }),
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}
