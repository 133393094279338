/* eslint-disable prettier/prettier */
/* eslint-disable indent */
import {
  flexRender,
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
} from "@tanstack/react-table";
import { Tooltip } from "antd";
import { useRef, useState } from "react";
import { useVirtual } from "react-virtual";

import arrowDown from "../../assets/images/arrow-down.svg";
import arrowUp from "../../assets/images/arrow-up.svg";
import { mixpanelActions } from "../../utils/mixpanel";
import {
  EmptyStateContainer,
  TableContainer,
  TableElement,
  ThSorted,
  ThUnsorted,
  tooltipStyles,
  Tr,
  ArrowImage,
  ArrowContent,
  isSorted,
} from "./TableSorted.styles";

interface ITableProps {
  columns: ColumnDef<any, any>[];
  data: any[];
  onRowClick?: (rowContent: any) => void;
  emptyStateText?: string;
  highlightColor?: string | string[];
}

export function TableSorted({
  columns,
  data,
  emptyStateText,
  onRowClick,
  highlightColor = "white",
}: ITableProps) {
  const [sorting, setSorting] = useState<SortingState>([]);

  function handleColumnVisibility(columns: ColumnDef<any, string>[]) {
    const columnVisibility: Record<string, boolean> = {};

    columns.forEach((column) => {
      if (column.size === 0 && (column as any).accessorKey) {
        columnVisibility[(column as any).accessorKey] = false;
      }
    });

    return columnVisibility;
  }

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
    initialState: {
      columnVisibility: handleColumnVisibility(columns),
    },
  });

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: rows.length,
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;

  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  const hasData = Boolean(data && data.length);

  let tablePrimaryColor = "white";

  const isColorsArray = Array.isArray(highlightColor);

  const handleTrackColumn = (column: string) => {
    mixpanelActions.track(`User Action: Sort ${column}`);
  };

  if (isColorsArray) {
    let index = 0;
    tablePrimaryColor = highlightColor[index];
    index = +1;
  }

  return (
    <TableContainer emptyState={!hasData} ref={tableContainerRef}>
      <TableElement>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return header.column.getCanSort() ? (
                  <ThSorted
                    style={{}}
                    key={header.id}
                    onClick={() => {
                      handleTrackColumn(header.id);
                      header.column.toggleSorting();
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <Tooltip
                        title={(() => {
                          if (header.column.getCanSort()) {
                            if (header.column.id === "name") {
                              const nextSortingOrder =
                                header.column.getNextSortingOrder();

                              if (nextSortingOrder === "asc") {
                                return "Sort first name A-Z";
                              }

                              if (nextSortingOrder === "desc") {
                                return "Sort first name Z-A";
                              }

                              return "Turn off sorting";
                            }

                            if (
                              header.column.id === "hospitalId" ||
                              header.column.id === "id"
                            ) {
                              const nextSortingOrder =
                                header.column.getNextSortingOrder();

                              if (nextSortingOrder === "asc") {
                                return "Sort lowest - highest";
                              }

                              if (nextSortingOrder === "desc") {
                                return "Sort highest - lowest";
                              }

                              return "Turn off sorting";
                            }

                            if (
                              header.column.id === "alerts" ||
                              header.column.id === "lastUsed" ||
                              header.column.id === "surveyReceived" ||
                              header.column.id === "signUpDate"
                            ) {
                              const nextSortingOrder =
                                header.column.getNextSortingOrder();

                              if (nextSortingOrder === "asc") {
                                return "Sort newest - oldest";
                              }

                              if (nextSortingOrder === "desc") {
                                return "Sort oldest - newest";
                              }

                              return "Turn off sorting";
                            }

                            if (
                              header.column.id === "surveyResult" ||
                              header.column.id === "lastReviewedBy"
                            ) {
                              const nextSortingOrder =
                                header.column.getNextSortingOrder();

                              if (nextSortingOrder === "asc") {
                                return "Sort A-Z";
                              }

                              if (nextSortingOrder === "desc") {
                                return "Sort Z-A";
                              }

                              return "Turn off sorting";
                            }
                          }

                          return undefined;
                        })()}
                        zIndex={3002}
                        color="#4B4B4B"
                        arrow={false}
                        placement="top"
                        styles={{
                          body: tooltipStyles,
                        }}
                      >
                        <ArrowContent
                          className={
                            header.column.getIsSorted() ? isSorted() : ""
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <ArrowImage src={arrowUp} />,
                            desc: <ArrowImage src={arrowDown} />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </ArrowContent>
                      </Tooltip>
                    )}
                  </ThSorted>
                ) : (
                  <ThUnsorted style={{}} key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </ThUnsorted>
                );
              })}
            </tr>
          ))}
        </thead>

        {hasData && (
          <tbody>
            <tr style={{ backgroundColor: tablePrimaryColor }}>
              <td aria-label="paddingTop" style={{ height: `$2px` }} />
            </tr>
            {paddingTop > 0 && (
              <tr style={{ backgroundColor: tablePrimaryColor }}>
                <td
                  aria-label="customPaddingTop"
                  style={{ height: `${paddingTop}px` }}
                />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];

              const handleOnRowClick = () => {
                if (!onRowClick) return;

                onRowClick(row.original);
              };

              return (
                <Tr onClick={handleOnRowClick} tabIndex={0} key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </Tr>
              );
            })}

            {paddingBottom > 0 && (
              <tr style={{ backgroundColor: tablePrimaryColor }}>
                <td
                  aria-label="customPaddingBottom"
                  style={{ height: `${paddingBottom}px` }}
                />
              </tr>
            )}
          </tbody>
        )}

        {hasData && <tfoot />}
      </TableElement>

      {!hasData && (
        <EmptyStateContainer>
          <p>{emptyStateText || "No data to show"}</p>
        </EmptyStateContainer>
      )}
    </TableContainer>
  );
}
