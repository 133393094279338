import { css } from "@stitches/react";
import { DatePicker } from "antd";
import { Dayjs } from "dayjs";

type Enumerate<
  N extends number,
  Acc extends number[] = []
> = Acc["length"] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc["length"]]>;

export type IntRange<F extends number, T extends number> = Exclude<
  Enumerate<T>,
  Enumerate<F>
>;

type DateTimePickerInputProps = {
  value?: Dayjs;
  defaultValue?: Dayjs;
  format?: string;
  onChange(dateTime: Dayjs, dateTimeString: string | string[]): void;
  width?: string;
  hideNowButton?: boolean;
  minuteStep?: IntRange<1, 59>;
  placeholder?: string;
  disabled?: boolean;
  minDate?: Dayjs;
  showTime?: boolean;
};

export function DateTimePickerInput({
  value,
  defaultValue,
  format,
  onChange,
  width,
  hideNowButton,
  minuteStep,
  placeholder,
  disabled,
  minDate,
  showTime = true,
}: DateTimePickerInputProps) {
  const dateTimePickerInputCss = css({
    height: "42px",
    width,

    fontFamily: "$openSans !important",
    border: "2px solid $gray-50 !important",

    "&:not(.ant-picker-disabled)": {
      "&:hover": {
        border: "2px solid #BDB4DD !important",
      },
    },

    "&.ant-picker-focused": {
      border: "2px solid #BDB4DD !important",
      boxShadow: "0 0 0 2px rgba(189,180,221, 0.1) !important",
    },

    "&.ant-picker-disabled": {
      "&:hover": {
        border: "2px solid $gray-50 !important", // Keep the original border color when disabled
      },
    },
  });

  const dateTimePickerPopupCss = css({
    zIndex: 3100,
    pointerEvents: "all !important",
    fontFamily: "$openSans !important",

    span: {
      fontFamily: "$openSans !important",
    },

    ".ant-picker-time-panel-column": {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },

    ".ant-picker-time-panel-cell-selected ": {
      ".ant-picker-time-panel-cell-inner": {
        background: "#E9E6F4 !important",
      },
    },

    ".ant-picker-cell-selected": {
      ".ant-picker-cell-inner": {
        background: "#BDB4DD !important",
      },
    },

    ".ant-picker-time-panel-column::after": {
      height: "0px !important",
    },

    ".ant-picker-cell-today .ant-picker-cell-inner::before": {
      borderColor: "#BDB4DD !important",
    },

    ".ant-picker-footer": {
      display: hideNowButton ? "none" : "block",

      ".ant-picker-ranges": {
        marginTop: "0",
        fontWeight: "400",

        ".ant-picker-now-btn": {
          color: "#212121",

          "&:hover": {
            opacity: "0.8",
          },
        },

        ".ant-picker-ok": {
          button: {
            "&.ant-btn-primary": {
              backgroundColor: "#7562A8",

              "&:hover": {
                backgroundColor: "#615492",
              },
            },
          },
        },
      },
    },
  });

  const disabledDate = minDate
    ? (d: Dayjs) => !d || d.isBefore(minDate)
    : undefined;

  return (
    <DatePicker
      defaultValue={defaultValue}
      placeholder={placeholder}
      needConfirm={false}
      value={value}
      format={format || "YYYY-MM-DD HH:mm"}
      onChange={onChange}
      suffixIcon={null}
      popupClassName={dateTimePickerPopupCss()}
      className={dateTimePickerInputCss()}
      allowClear
      showTime={showTime}
      popupStyle={{}}
      minuteStep={minuteStep}
      disabled={disabled}
      disabledDate={disabledDate}
    />
  );
}
