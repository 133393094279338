import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import {
  IPromsTableData,
  ISurveyPromsTableData,
  ISurveysForms,
  ISurveysResponse,
} from "../../@types/Proms";
import { promsService } from "../../services/promsService";
import { getLongFormattedDate } from "../../utils/dateFormatter";

function roundScoreToOneDecimal(score: number) {
  return Math.round(score * 10) / 10;
}

export function getPatientPromsData(patientId: number) {
  const { data, isFetching, isError, refetch, isRefetching } = useQuery<
    ISurveysResponse[] | undefined
  >({
    queryKey: [`patient-proms-${patientId}`, patientId],
    queryFn: async () => {
      if (!patientId) return undefined;

      const { data } = await promsService.getPatientProms(patientId);

      return data.surveys as ISurveysResponse[];
    },
  });

  const patientPromsData = data as ISurveysResponse[];

  const getLastCompletedDate = (lastCompleted: any) => {
    if (lastCompleted) return dayjs(lastCompleted).toDate();
    return 0;
  };

  return {
    proms: patientPromsData?.map((promsData) => {
      const lastcompletedDateString = getLongFormattedDate(
        promsData.lastCompleted
      );
      return {
        ...promsData,
        reminders: promsData.reminders ? "Active" : "Inactive",
        adherence: `${Math.round(promsData.adherence ?? 0)}%`,
        assigned: getLongFormattedDate(promsData.assigned),
        frequency: promsData.frequency ?? "-",
        surveyResult: "-",
        lastTotalScore: promsData.lastTotalScore
          ? roundScoreToOneDecimal(promsData.lastTotalScore)
          : "-",
        lastCompleted: lastcompletedDateString,
        lastCompletedDate: getLastCompletedDate(promsData.lastCompleted),
      } as IPromsTableData;
    }),
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}

export function getPatientSurveyFormsData(patientId: number, surveyId: number) {
  const { data, isFetching, isError, refetch, isRefetching } = useQuery<
    ISurveysForms[] | undefined
  >({
    queryKey: [`patient-survey-forms-${patientId}-${surveyId}`, patientId],
    queryFn: async () => {
      if (!patientId || !surveyId) return undefined;

      const { data } = await promsService.getPatientSurveyForms(
        patientId,
        surveyId
      );

      return data.surveyForms as ISurveysForms[];
    },
  });

  const patientSurveyFormsData = data as ISurveysForms[];

  return {
    surveyForms: patientSurveyFormsData?.map((formsData) => {
      return {
        ...formsData,
        time: getLongFormattedDate(formsData.time),
      } as ISurveyPromsTableData;
    }),
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}
