import { useQuery } from "@tanstack/react-query";

import { IHospitalStaffResponse, IStaffTableData } from "../../@types/Staff";
import { staffService } from "../../services/staffService";

export function getTableHospitalStaff() {
  const { data, isFetching, isError, refetch, isRefetching } = useQuery<
    IHospitalStaffResponse[]
  >({
    queryKey: ["table-hospital-staff"],
    queryFn: async () => {
      const { data } = await staffService.getHospitalStaff();

      return data;
    },
  });

  return {
    staff: data?.map((staffData) => {
      return {
        ...staffData,
        isAdmin: staffData.isAdmin ? "Yes" : "No",
        isActive: staffData.isActive ? "Yes" : "No",
      } as IStaffTableData;
    }),
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}
