import { ColumnDef } from "@tanstack/react-table";

export const defaultTableColumns: ColumnDef<any, string>[] = [
  {
    accessorKey: "name",
    header: () => "Name",
    cell: (info) => info.renderValue(),
    size: 220,
    sortingFn: "text",
  },
  {
    accessorKey: "hospitalPatientId",
    header: () => "Hospital ID",
    cell: (info) => info.renderValue(),
    size: 150,
    sortingFn: "text",
  },
  {
    accessorKey: "pmpId",
    header: () => "pMp ID",
    cell: (info) => info.renderValue(),
    sortingFn: "basic",
  },
  {
    accessorKey: "numberOfBillableDays",
    header: () => "No. of days recording data",
    cell: (info) => info.renderValue(),
    sortingFn: "basic",
  },
  {
    accessorKey: "signUpDate",
    header: () => "Sign up date",
    cell: (info) => info.renderValue(),
    size: 160,
    sortingFn: (rowA, rowB) => {
      return (
        rowB.original.signUpdateParsedDate - rowA.original.signUpdateParsedDate
      );
    },
  },
  {
    accessorKey: "lastUsed",
    header: () => "Last used",
    cell: (info) => info.renderValue(),
    size: 170,
    sortingFn: (rowA, rowB) => {
      return rowB.original.lastUsedDate - rowA.original.lastUsedDate;
    },
  },
  {
    accessorKey: "dob",
    header: () => "DOB",
    cell: (info) => info.renderValue(),
    size: 150,
    enableSorting: false,
  },
  {
    accessorKey: "action",
    header: () => "",
    cell: (info) => info.renderValue(),
    size: 160,
    enableSorting: false,
  },
];
