import { AxiosResponse } from "axios";

import { api } from "./api";

export const reportService = {
  async downloadBillingReport(
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse<Blob>> {
    const url = "/Portal/Report/GenerateBillingReport";

    const serverResponse = await api.post(
      url,
      {
        fromDate: startDate,
        toDate: endDate,
      },
      { responseType: "blob" }
    );

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async downloadPatientReport(
    patientId: number,
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse<Blob>> {
    const url = "/Portal/Report/GeneratePatientReport";

    const serverResponse = await api.post(
      url,
      {
        patientId,
        fromDate: startDate,
        toDate: endDate,
      },
      { responseType: "blob" }
    );

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },
};
