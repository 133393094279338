export function DollarBill({ ...rest }) {
  return (
    <svg {...rest} viewBox="0 -3 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.08331 12.5833H10.9166V11.6666H11.8333C12.093 11.6666 12.3107 11.5788 12.4864 11.4031C12.6621 11.2274 12.75 11.0097 12.75 10.75V7.99996C12.75 7.74024 12.6621 7.52253 12.4864 7.34683C12.3107 7.17114 12.093 7.08329 11.8333 7.08329H9.08331V6.16663H12.75V4.33329H10.9166V3.41663H9.08331V4.33329H8.16665C7.90692 4.33329 7.68922 4.42114 7.51352 4.59683C7.33783 4.77253 7.24998 4.99024 7.24998 5.24996V7.99996C7.24998 8.25968 7.33783 8.47739 7.51352 8.65308C7.68922 8.82878 7.90692 8.91663 8.16665 8.91663H10.9166V9.83329H7.24998V11.6666H9.08331V12.5833ZM2.66665 15.3333C2.16248 15.3333 1.73088 15.1538 1.37185 14.7948C1.01283 14.4357 0.833313 14.0041 0.833313 13.5V2.49996C0.833313 1.99579 1.01283 1.5642 1.37185 1.20517C1.73088 0.84614 2.16248 0.666626 2.66665 0.666626H17.3333C17.8375 0.666626 18.2691 0.84614 18.6281 1.20517C18.9871 1.5642 19.1666 1.99579 19.1666 2.49996V13.5C19.1666 14.0041 18.9871 14.4357 18.6281 14.7948C18.2691 15.1538 17.8375 15.3333 17.3333 15.3333H2.66665ZM2.66665 13.5H17.3333V2.49996H2.66665V13.5Z"
      />
    </svg>
  );
}
