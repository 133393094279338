import { Navigate, RouteObject } from "react-router-dom";

import { DashboardLayout } from "../layout/components/DashboardLayout";
import { AppointmentsPage } from "../pages/Appointments";
import { BillingPatientList } from "../pages/BillingPatientList";
import { CareInfo } from "../pages/CareInfo";
import { ArticleViewer } from "../pages/CareInfo/components/ArticleViewer";
import { Dashboard } from "../pages/Dashboard";
import { ForgotPasswordPage } from "../pages/ForgotPassword";
import { LoginPage } from "../pages/Login";
import { MFAPage } from "../pages/MFA";
import { MobileUserSurveyList } from "../pages/MobileUserSurveyList";
import { NotFound } from "../pages/NotFound";
import { PatientList } from "../pages/PatientList";
import { PatientPage } from "../pages/PatientPage";
import { WorkflowsList } from "../pages/PatientWorkflows";
import { WorkflowsBuilder } from "../pages/PatientWorkflows/components/WorkflowsBuilder";
import { ProtocolList } from "../pages/Protocol";
import { ProtocolBuilder } from "../pages/Protocol/components/ProtocolBuilder";
import { SetPasswordPage } from "../pages/SetPassword";
import { CreatePassSuccessPage } from "../pages/SetPassword/CreatePassSuccessPage";
import { SettingsPage } from "../pages/Settings";
import { AnswerSurveyPage } from "../pages/Surveys/Answer Survey";
import { CreateSurveyPage } from "../pages/Surveys/CreateSurvey";
import { SurveysPage } from "../pages/Surveys/SurveyList/SurveyListPage";
import { patientListChildRoutes, patientPageChildRoutes } from "./child.routes";

export const routes: RouteObject[] = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/mfa",
    element: <MFAPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/set-password-success",
    element: <CreatePassSuccessPage />,
  },
  {
    path: "/set-password",
    element: <SetPasswordPage />,
  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/patients" />,
      },
      {
        path: "/patients",
        children: [
          { index: true, element: <Navigate to="active" /> },
          {
            path: "active",
            element: <PatientList variant="active" />,
          },
          {
            path: "archived",
            element: <PatientList variant="archived" />,
          },
          {
            path: "flagged",
            element: <PatientList variant="flagged" />,
          },
          {
            path: "alerts",
            element: <PatientList variant="alerts" />,
          },
          {
            path: "billing",
            element: <BillingPatientList />,
          },
          {
            path: ":patientId",
            children: [
              {
                index: true,
                element: <Navigate to="overview" />,
              },
              {
                path: "overview",
                element: <PatientPage />,
              },
              {
                path: "pinned",
                element: <PatientPage />,
              },
              {
                path: "measurements",
                element: <PatientPage />,
              },
              {
                path: "spirometry",
                element: <PatientPage />,
              },
              {
                path: "labs",
                element: <PatientPage />,
              },
              {
                path: "alerts",
                element: <PatientPage />,
              },
              {
                path: "proms",
                element: <PatientPage />,
              },
              {
                path: "notes",
                element: <PatientPage />,
              },
              {
                path: "medication",
                element: <PatientPage />,
              },
              {
                path: "journal",
                element: <PatientPage />,
              },
              {
                path: "messages",
                element: <PatientPage />,
              },
              {
                path: "devices",
                element: <PatientPage />,
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardLayout leftPanelHidden />,
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard" />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
    ],
  },
  {
    path: "/settings",
    element: <SettingsPage />,
  },
  {
    path: "/",
    element: <DashboardLayout leftPanelHidden />,
    children: [
      {
        index: true,
        element: <Navigate to="/surveys" />,
      },
      {
        path: "/surveys",
        element: <SurveysPage />,
      },
      {
        path: "/surveys/answer",
        element: <MobileUserSurveyList />,
      },
      {
        path: "/surveys/new",
        element: <CreateSurveyPage />,
      },
    ],
  },
  {
    path: "/surveys/answer/:userSurveyId",
    element: <AnswerSurveyPage />,
  },
  {
    path: "/",
    element: <DashboardLayout leftPanelHidden />,
    children: [
      {
        index: true,
        element: <Navigate to="/protocol" />,
      },
      {
        path: "/protocol",
        element: <ProtocolList />,
      },
      {
        path: "/protocol/builder",
        element: <ProtocolBuilder />,
      },
      {
        path: "/protocol/builder/:protocolId",
        element: <ProtocolBuilder />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardLayout leftPanelHidden />,
    children: [
      {
        index: true,
        element: <Navigate to="/appointments" />,
      },
      {
        path: "/appointments",
        element: <AppointmentsPage />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardLayout leftPanelHidden />,
    children: [
      {
        index: true,
        element: <Navigate to="/careinfo" />,
      },
      {
        path: "/careinfo",
        element: <CareInfo />,
      },
    ],
  },
  {
    path: "/careinfo/view/:articleId",
    element: <ArticleViewer />,
  },
  {
    path: "/",
    element: <DashboardLayout leftPanelHidden />,
    children: [
      {
        index: true,
        element: <Navigate to="/workflows" />,
      },
      {
        path: "/workflows",
        element: <WorkflowsList />,
      },
      {
        path: "/workflows/builder",
        element: <WorkflowsBuilder />,
      },
      {
        path: "/workflows/builder/:workflowId",
        element: <WorkflowsBuilder />,
      },
    ],
  },
];

export const routesWithChildRoutes = [
  {
    paths: [
      "/patients/active",
      "/patients/alerts",
      "/patients/flagged",
      "/patients/archived",
      "/patients/billing",
    ],
    childRoutes: patientListChildRoutes,
  },
  {
    paths: [
      "/patients/:patientId/overview",
      "/patients/:patientId/pinned",
      "/patients/:patientId/measurements",
      "/patients/:patientId/spirometry",
      "/patients/:patientId/labs",
      "/patients/:patientId/alerts",
      "/patients/:patientId/proms",
      "/patients/:patientId/notes",
      "/patients/:patientId/medication",
      "/patients/:patientId/journal",
      "/patients/:patientId/messages",
      "/patients/:patientId/devices",
    ],
    childRoutes: patientPageChildRoutes,
  },
];
